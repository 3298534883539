"use strict";

var _objectWithoutProperties = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250130181725/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectWithoutProperties");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var react_materialize_1 = require("react-materialize");

exports.Checkbox = function (props) {
  var isChecked = props.isChecked,
      isDisabled = props.isDisabled,
      label = props.label,
      _props$className = props.className,
      className = _props$className === void 0 ? '' : _props$className,
      _props$onChange = props.onChange,
      onChange = _props$onChange === void 0 ? function () {} : _props$onChange,
      otherProps = _objectWithoutProperties(props, ["isChecked", "isDisabled", "label", "className", "onChange"]);

  return React.createElement(react_materialize_1.Input, Object.assign({
    type: "checkbox",
    label: label || React.createElement("span", null, "\xA0"),
    className: "filled-in material-cb ".concat(className),
    checked: isChecked,
    onClick: function onClick(event) {
      event.stopPropagation();
      onChange();
    },
    disabled: isDisabled
  }, otherProps));
};