"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250130181725/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var HPSkins;

(function (HPSkins) {
  var _HPSkins$ORDER_STATUS;

  var ORDER_STATUSES;

  (function (ORDER_STATUSES) {
    ORDER_STATUSES["READY_FOR_PRODUCTION"] = "HP_SKINS_ORDER_STATUS_READY_FOR_PRODUCTION";
    ORDER_STATUSES["PRINTED"] = "HP_SKINS_ORDER_STATUS_PRINTED";
    ORDER_STATUSES["SHIPPED"] = "HP_SKINS_ORDER_STATUS_SHIPPED";
  })(ORDER_STATUSES = HPSkins.ORDER_STATUSES || (HPSkins.ORDER_STATUSES = {}));

  HPSkins.ORDER_STATUSES_TRANSLATIONS = (_HPSkins$ORDER_STATUS = {}, _defineProperty(_HPSkins$ORDER_STATUS, ORDER_STATUSES.READY_FOR_PRODUCTION, 'A imprimer'), _defineProperty(_HPSkins$ORDER_STATUS, ORDER_STATUSES.PRINTED, 'A expédier'), _defineProperty(_HPSkins$ORDER_STATUS, ORDER_STATUSES.SHIPPED, 'Expédié'), _HPSkins$ORDER_STATUS);
})(HPSkins = exports.HPSkins || (exports.HPSkins = {}));